import Cookies from 'universal-cookie'
import { useStore } from '@enocloud/utils'
import { computed } from 'vue'

export interface MenuConfig {
  id: string
  message: string
  path?: string
  code?: string | string[]
  children?: MenuConfig[]
  type?: 'root' | 'branch' | 'leaf'
}

export const settingMenus: MenuConfig[] = [
  {
    id: 'SETTING_TENANT',
    message: '网点',
    path: '/setting/tenant/user',
    children: [
      { id: 'SETTING_TENANT_USER', message: '人员', code: 'USER', type: 'leaf', path: '/setting/tenant/user' },
      { id: 'SETTING_TENANT_ROLE', message: '角色', code: 'ROLE', type: 'leaf', path: '/setting/tenant/role' },
      { id: 'SETTING_TENANT_BRANCH', message: '连锁店', code: 'BRANCH', type: 'leaf', path: '/setting/tenant/branch' },
      { id: 'SETTING_TENANT_NOTE', message: '日志', code: 'RELEASE_NOTE_QUERY', type: 'leaf', path: '/setting/tenant/note' },
      { id: 'SETTING_TENANT_ATTRIBUTE', message: '系统设置', code: 'BRANCH_ATTRIBUTE_QUERY', type: 'leaf', path: '/setting/tenant/attribute' },
      // { id: 'SETTING_TENANT_TEMPLATE', message: '打印设置', code: 'REPORT_TEMPLATE_MANAGEMENT', type: 'leaf', path: '/setting/tenant/template' },
      { id: 'SETTING_TENANT_SECURITY', message: '安全设置', code: 'LICENCE_MANAGEMENT', type: 'leaf', path: '/setting/tenant/licence' }
    ]
  },
  {
    id: 'SETTING_BUSINESS',
    message: '业务',
    path: '/setting/business/maintenance',
    children: [
      { id: 'SETTING_MAINTENANCE', message: '维修项目', code: 'MAINTENANCE_QUERY', type: 'leaf', path: '/setting/business/maintenance' },
      {
        id: 'SETTING_MAINTENANCE_CATEGORY',
        message: '维修项目类别',
        code: 'MAINTENANCE_CATEGORY_QUERY',
        type: 'leaf',
        path: '/setting/business/maintenance/category'
      },
      { id: 'SETTING_MAINTENANCE_CATEGORY', message: '险种项目', code: 'INSURANCE', type: 'leaf', path: '/setting/business/maintenance/insurance' },
      {
        id: 'SETTING_MAINTENANCE_CATEGORY',
        message: '维修类别',
        code: 'SERVICE_CATEGORY_QUERY',
        type: 'leaf',
        path: '/setting/business/service/category'
      },
      {
        id: 'SETTING_INSPECTION_CATEGORY',
        message: '检测类别',
        code: 'VEHICLE_INSPECTION_QUERY',
        type: 'leaf',
        path: '/setting/business/inspection/category'
      },
      {
        id: 'SETTING_INSPECTION_ITEM',
        message: '检测项目',
        code: 'VEHICLE_INSPECTION_QUERY',
        type: 'leaf',
        path: '/setting/business/inspection/maintenance'
      },
      {
        id: 'SETTING_INSPECTION_PLAN',
        message: '检测方案',
        code: 'VEHICLE_INSPECTION_QUERY',
        type: 'leaf',
        path: '/setting/business/inspection/plan'
      },
      { id: 'SETTING_ADVISORTEAM', message: '服务顾问', code: 'STATEMENT_ADVISOR', type: 'leaf', path: '/setting/business/inspection/advisorteam' },
      { id: 'SETTING_WORKINGTEAM', message: '班组设置', code: 'WORKING_TEAM_QUERY', type: 'leaf', path: '/setting/business/inspection/workingteam' },
      {
        id: 'SETTING_INSPECTION_USER',
        message: '终检人配置',
        code: 'FINAL_INSPECTOR_QUERY',
        type: 'leaf',
        path: '/setting/business/inspection/user'
      },
      { id: 'SETTING_FORM_FIELDS', message: '表单配置', code: 'FORM_SETTING_QUERY', type: 'leaf', path: '/setting/business/goods/form-fields' },
      { id: 'SETTING_AUTITOR', message: '审核人设置', code: 'DOCUMENT_AUDITOR', type: 'leaf', path: '/setting/business/goods/auditor' },
      { id: 'SETTING_GOODS', message: '配件', code: 'GOODS_QUERY', type: 'leaf', path: '/setting/business/goods/goods' },
      { id: 'SETTING_GOODS_CATEGORY', message: '配件类别', code: 'GOODS_CATEGORY_QUERY', type: 'leaf', path: '/setting/business/goods/category' },
      { id: 'SETTING_GOODS_INCREMENT', message: '配件加价率', code: 'PRICE_RANGE_QUERY', type: 'leaf', path: '/setting/business/vehicle/increment' },
      { id: 'SETTING_WAREHOUSE', message: '仓库', code: 'WAREHOUSE_QUERY', type: 'leaf', path: '/setting/business/vehicle/warehouse' },
      { id: 'SETTING_ELECTRONIC', message: '电子档案', code: 'ELEC_DOC', type: 'leaf', path: '/setting/business/vehicle/electronic' },
      { id: 'SETTING_VEHICLE_TYPE', message: '车品牌类别', code: 'VEHICLE_TYPE_QUERY', type: 'leaf', path: '/setting/business/vehicle/vehicle-type' },
      {
        id: 'SETTING_CUSTOMER_CATEGORY',
        message: '客户分类',
        code: 'CUSTOMER_CATEGORY_QUERY',
        type: 'leaf',
        path: '/setting/business/vehicle/customer-category'
      },
      { id: 'SETTING_SUPPLIER', message: '供应商', code: 'CUSTOMER_QUERY', type: 'leaf', path: '/setting/business/vehicle/supplier' },
      { id: 'SETTING_INSURANCE', message: '保险公司', code: '保险公司', type: 'leaf', path: '/setting/business/vehicle/insurance' },
      { id: 'SETTING_HINT', message: '提示信息', code: 'HINT', type: 'leaf', path: '/setting/business/vehicle/hint' }
    ]
  },
  {
    id: 'SETTING_ENOCH',
    message: 'ENOCH',
    path: '/setting/enoch/cloud',
    children: [
      { id: 'SETTING_ENOCH-CLOUD', message: '汽修网点', code: 'CLOUD_TENANT_QUERY', type: 'leaf', path: '/setting/enoch/cloud' },
      // { id: 'SETTING_ENOCH-TENANT', message: '喷涂网点', code: 'SPRAY_TENANT_QUERY', type: 'leaf', path: '/setting/enoch/tenant' },
      { id: 'SETTING_ENOCH-DASHBOARD', message: '数据看板', code: 'DATA_BOARD', type: 'leaf', path: '/setting/enoch/dashboard' },
      { id: 'SETTING_ENOCH-WORKSTATISTIC', message: '工单统计', code: 'SSO_SERVICE_MANAGEMENT', type: 'leaf', path: '/setting/enoch/workstatistic' },
      { id: 'SETTING_ENOCH-ATTRIBUTE', message: '系统设置', code: 'BRANCH_ATTRIBUTE_QUERY', type: 'leaf', path: '/setting/enoch/attribute' },
      // { id: 'SETTING_ENOCH-PAINT', message: '喷涂配件', code: 'SPRAY_GOODS', type: 'leaf', path: '/setting/enoch/paint' },
      // { id: 'SETTING_ENOCH-CHARGE', message: 'A单色母', code: 'CHARGING_STANDARD', type: 'leaf', path: '/setting/enoch/charge' },
      // { id: 'SETTING_ENOCH-SERVICEP', message: '服务商配置', code: 'CUSTOMER_AGENCY_QUERY', type: 'leaf', path: '/setting/enoch/servicep' },
      // {
      //   id: 'SETTING_ENOCH-MANUFACTURING',
      //   message: '配件生产配置',
      //   code: 'MANUFACTURING_PRODUCTION_QUERY',
      //   type: 'leaf',
      //   path: '/setting/enoch/manufacturing'
      // },
      // todo:ENOCH一定有
      // { id: 'SETTING_ENOCH-SALESMAN', message: '业务人员设置', code: '', type: 'leaf', path: '/setting/enoch/salesman' },
      {
        id: 'SETTING_ENOCH-BASE-REPORT',
        message: '网点打印设置',
        code: 'REPORT_TEMPLATE_SAMPLE_MANAGEMENT',
        type: 'leaf',
        path: '/setting/enoch/base-report'
      },
      // todo:ENOCH一定有
      { id: 'SETTING_ENOCH-QUESTION', message: '问题工单', code: '', type: 'leaf', path: '/setting/enoch/question' },
      { id: 'SETTING_ENOCH-APPLICATION', message: '报名列表', code: 'SPRAY_MALL_APPLICATION', type: 'leaf', path: '/setting/enoch/application' },
      { id: 'SETTING_ENOCH-COMBO', message: '短信套餐', code: 'SPRAY_SHORT_MESSAGE_COMBO_QUERY', type: 'leaf', path: '/setting/enoch/combo' },
      {
        id: 'SETTING_ENOCH-MESSAGE',
        message: '短信充值订单',
        code: 'SPRAY_SHORT_MESSAGE_COMBO_ORDER_QUERY',
        type: 'leaf',
        path: '/setting/enoch/message'
      }
      // { id: 'SETTING_ENOCH-SUMMARY', message: '投屏数据', code: 'SPRAY_MALL_SUMMARY', type: 'leaf', path: '/setting/enoch/summary' },
      // { id: 'SETTING_ENOCH-SUMMARY-SETTING', message: '投屏设置', code: 'SPRAY_MALL_SUMMARY', type: 'leaf', path: '/setting/enoch/summary-setting' },
      // { id: 'SETTING_ENOCH-PACKET', message: '二维码营销', code: 'MALL_MARKETING_PROMOTION', type: 'leaf', path: '/setting/enoch/packet' }
    ]
  }
]

const menus: MenuConfig[] = [
  {
    id: 'SERVICE',
    message: '维修',
    children: [
      {
        id: 'SERVICE_SERVICE',
        message: '维修',
        children: [
          {
            id: 'SERVICE_SERVICE_WORKORDER',
            message: '维修',
            code: 'SERVICE_QUERY',
            type: 'leaf',
            path: '/service/business/workorder'
          },
          { id: 'SERVICE_SERVICE_RESERVATION', message: '预约', code: 'SERVICE_RESERVATION_QUERY', type: 'leaf', path: '/service/reservation' },
          { id: 'SERVICE_SERVICE_POLICY', message: '保单', code: 'POLICY_QUERY', type: 'leaf', path: '/service/policy' }
        ]
      }
    ]
  },
  {
    id: 'ACCESSORY',
    message: '配件',
    children: [
      {
        id: 'ACCESSORY_INVENTORY',
        message: '库房',
        children: [
          {
            id: 'ACCESSORY_INVENTORY_REQUESTION',
            message: '维修领料',
            code: 'SERVICE_MATERIAL_QUERY',
            type: 'leaf',
            path: '/accessory/inventory/requestion'
          },
          {
            id: 'ACCESSORY_INVENTORY_REQUESTION_INTERNAL',
            message: '内部领料',
            code: 'MATERIAL_REQUISITION_INTERNAL_QUERY',
            type: 'leaf',
            path: '/accessory/inventory/requestion'
          },
          {
            id: 'ACCESSORY_INVENTORY_REQUESTION_DETAILS',
            message: '领退料明细',
            code: 'SERVICE_MATERIAL_HISTORY',
            type: 'leaf',
            path: '/accessory/inventory/requestion/details'
          },
          { id: 'ACCESSORY_INVENTORY_GOODS', message: '库存配件', code: 'INVENTORY_WAREHOUSE', type: 'leaf', path: '/accessory/inventory/goods' },
          { id: 'ACCESSORY_INVENTORY_HISTORY', message: '库存流水', code: 'INVENTORY_HISTORY', type: 'leaf', path: '/accessory/inventory/history' },
          {
            id: 'ACCESSORY_INVENTORY_ADJUSTMENT',
            message: '盘点调价',
            code: 'STOCK_TAKING_QUERY',
            type: 'leaf',
            path: '/accessory/inventory/adjustment'
          },
          {
            id: 'ACCESSORY_INVENTORY_ADJUSTMENT_DETAILS',
            message: '盘点调价明细',
            code: 'STOCK_TAKING_GOODS',
            type: 'leaf',
            path: '/accessory/inventory/adjustment/details'
          }
        ]
      },
      {
        id: 'ACCESSORY_STOCK_IN',
        message: '入库',
        children: [
          { id: 'ACCESSORY_STOCK_IN_PURCHASE', message: '配件入库', code: 'PURCHASE_IN_QUERY', type: 'leaf', path: '/accessory/stock/in/purchase' },
          {
            id: 'ACCESSORY_STOCK_IN_PURCHASE_DETAILS',
            message: '配件入库明细',
            code: 'PURCHASE_IN_GOODS',
            type: 'leaf',
            path: '/accessory/stock/in/details'
          }
        ]
      },
      {
        id: 'ACCESSORY_STOCK_OUT',
        message: '出库',
        children: [
          { id: 'ACCESSORY_STOCK_IN_SALE', message: '配件出库', code: 'SALE_OUT_QUERY', type: 'leaf', path: '/accessory/stock/out/sale' },
          {
            id: 'ACCESSORY_STOCK_IN_SALE_DETAILS',
            message: '配件出库明细',
            code: 'SALE_OUT_GOODS',
            type: 'leaf',
            path: '/accessory/stock/out/details'
          }
        ]
      }
    ]
  },
  {
    id: 'SETTLEMENT',
    message: '财务',
    children: [
      {
        id: 'SETTLEMENT_RECEIVABLE',
        message: '收款',
        children: [
          { id: 'SETTLEMENT_RECEIVABLE', message: '收款', code: 'RECEIVABLE_QUERY', type: 'leaf', path: '/settlement/receivable' },
          { id: 'SETTLEMENT_RECEIVABLE_ORDER', message: '收款单', code: 'RECEIPT_QUERY', type: 'leaf', path: '/settlement/receivable/payorder' },
          { id: 'SETTLEMENT_RECEIVABLE_DETAIL', message: '收款明细', code: 'RECEIPT_QUERY', type: 'leaf', path: '/settlement/receivable/details' },
          {
            id: 'SETTLEMENT_RECEIVABLE_SUMMARY',
            message: '应收款统计',
            code: 'RECEIPT_STATISTICS_QUERY',
            type: 'leaf',
            path: '/settlement/receivable/summary'
          },
          { id: 'SETTLEMENT_RECEIVABLE', message: '定金列表', code: 'RECEIVABLE_QUERY', type: 'leaf', path: '/settlement/receivable/order' }
        ]
      },
      {
        id: 'SETTLEMENT_PAYABLE',
        message: '付款',
        children: [
          { id: 'SETTLEMENT_PAYABLE', message: '付款', code: 'PAYABLE_QUERY', type: 'leaf', path: '/settlement/payable' },
          // （功能暂不做)
          // { id: 'SETTLEMENT_PAYABLE', message: '应付对账单', code: 'PAYABLE_QUERY', type: 'leaf', path: '/settlement/payable/statement' },

          // todo: 配置 （对账流程） 是-应付对账单 否-付款
          { id: 'SETTLEMENT_PAYMENT', message: '付款单', code: 'PAYMENT_QUERY', type: 'leaf', path: '/settlement/payable/pay' },
          {
            id: 'SETTLEMENT_PAYABLE_PURCHASE_DETAIL',
            message: '采购付款明细',
            code: 'PURCHASE_PAYMENT_QUERY',
            type: 'leaf',
            path: '/settlement/payable/details'
          },
          {
            id: 'SETTLEMENT_PAYABLE_SUMMARY',
            message: '应付款统计',
            code: 'PAYMENT_STATISTICS_QUERY',
            type: 'leaf',
            path: '/settlement/payable/summary'
          }
        ]
      }
    ]
  },
  {
    id: 'REPORT',
    message: '报表',
    children: [
      {
        id: 'REPORT_SERVICE',
        message: '维修',
        children: [
          { id: 'REPORT_SERVICE_SERVICE', message: '维修统计', type: 'leaf', code: 'STATEMENT_CUSTOMER_SERVICE', path: '/report/maintain' },
          { id: 'REPORT_SERVICE_VEHICLE', message: '新车辆维修统计', type: 'leaf', code: 'STATEMENT_SERVICE', path: '/report/new-vehicle' },
          {
            id: 'REPORT_SERVICE_INVENTORY',
            message: '库存统计',
            type: 'leaf',
            code: ['STATEMENT_STOCK_IN', 'STATEMENT_STOCK_OUT', 'STATEMENT_STOCK'],
            path: '/report/stock'
          }
        ]
      },
      {
        id: 'REPORT_USER',
        message: '业绩',
        children: [
          {
            id: 'REPORT_USER_STAFF',
            message: '员工业绩统计',
            type: 'leaf',
            code: ['STATEMENT_ADVISOR', 'STATEMENT_WORKING_TEAM'],
            path: '/report/staff'
          },
          { id: 'REPORT_USER_BONUS', message: '员工提成统计', type: 'leaf', code: 'STATEMENT_BONUS_SUMMARY', path: '/report/bonus' },
          {
            id: 'REPORT_USER_PERSON',
            message: '定损员业绩',
            type: 'leaf',
            code: 'STATEMENT_SERVICE_ACCIDENT_SETTLE_PERSON',
            path: '/report/settle-person'
          }
        ]
      },
      {
        id: 'REPORT_STATISTICS',
        message: '营业',
        children: [
          { id: 'REPORT_STATISTICS_SERVICE', message: '利润报表', type: 'leaf', code: 'STATEMENT_PROFIT', path: '/report' },
          { id: 'REPORT_STATISTICS_BUSINESS', message: '营业统计', type: 'leaf', code: 'STATEMENT_SERVICE', path: '/report/business-statistics' },
          {
            id: 'REPORT_STATISTICS_STATEMENT',
            message: '收付款统计',
            type: 'leaf',
            code: ['STATEMENT_RECEIPT', 'STATEMENT_PAYMENT'],
            path: '/report/statement'
          },
          {
            id: 'REPORT_STATISTICS_MANAGER',
            message: '经营状况统计',
            type: 'leaf',
            code: ['STATEMENT_RECEIPT', 'STATEMENT_PAYMENT'],
            path: '/report/manager-output'
          }
        ]
      }
    ]
  },
  {
    id: 'QUERY',
    message: '查询',
    children: [
      {
        id: 'QUERY_WORKORDER',
        message: '工单',
        children: [
          {
            id: 'QUERY_WORKORDER_QUERY',
            message: '工单查询',
            code: [
              'STATEMENT_QUERY_SERVICE_AMOUNT',
              'STATEMENT_QUERY_SERVICE_MAINTENANCE',
              'STATEMENT_QUERY_SERVICE_GOODS',
              'STATEMENT_QUERY_SERVICE_REWORK_INTERNAL',
              'STATEMENT_QUERY_SERVICE_REWORK_EXTERNAL',
              'STATEMENT_QUERY_SERVICE_OTHER_FEE',
              'STATEMENT_QUERY_SERVICE_CANCEL',
              'STATEMENT_QUERY_SERVICE_ACCIDENT',
              'STATEMENT_QUERY_SERVICE_FAKE'
            ],
            type: 'leaf',
            path: '/query/workorder'
          }
        ]
      },
      {
        id: 'QUERY_DATA',
        message: '数据',
        children: [
          {
            id: 'QUERY_DATA_TOPUP',
            message: '数据查询',
            code: 'DATA_QUERY',
            type: 'leaf',
            path: '/query/data'
          },
          {
            id: 'QUERY_DATA_QUERY',
            message: '数据充值',
            code: 'DATA_QUERY',
            type: 'leaf',
            path: '/query/top-up'
          }
        ]
      },
      {
        id: 'QUERY_STATISTICS',
        message: '综合',
        children: [
          {
            id: 'QUERY_STATISTICS_QUERY',
            message: '综合查询',
            code: [
              'POLICY_INSURANCE_QUERY',
              'SERVICE_TECHNOLOGY_QUERY',
              'STATEMENT_QUERY_SERVICE_GOODS',
              'SERVICE_QUOTATION_MAINTENANCE_QUERY',
              'SERVICE_QUOTATION_GOODS_QUERY'
            ],
            type: 'leaf',
            path: '/query/statistics'
          }
        ]
      }
    ]
  },
  {
    id: 'CLIENT',
    message: '客户',
    children: [
      {
        id: 'CLIENT_CUSTOMER',
        message: '客户',
        children: [
          {
            id: 'CLIENT_CUSTOMER_VEHICLE',
            message: '客户车辆',
            code: ['CUSTOMER_QUERY', 'VEHICLE_QUERY'],
            type: 'leaf',
            path: '/client/customer/vehicle'
          },
          {
            id: 'CLIENT_CUSTOMER_TOPUP',
            message: '客户充值',
            code: ['TOPUP_QUERY', 'CHARGEABLE_QUERY'],
            type: 'leaf',
            path: '/client/customer/topup'
          },
          {
            id: 'CLIENT_CUSTOMER_REWARDPOINT',
            message: '客户积分',
            code: 'ACCOUNT_MEMBER_QUERY',
            type: 'leaf',
            path: '/client/customer/reward-point'
          }
        ]
      },
      {
        id: 'CLIENT_MARKET',
        message: '推广',
        children: [
          {
            id: 'CLIENT_MARKET_SHORT_MESSAGE',
            message: '短信管理',
            code: ['SHORT_MESSAGE_COMBO_ORDER_QUERY', 'SHORT_MESSAGE_SENDING_ORDER_QUERY'],
            type: 'leaf',
            path: '/client/market/short-message'
          },
          {
            id: 'CLIENT_MARKET_MALL',
            message: '营销活动',
            code: ['MALL_GROUP_PURCHASE_QUERY', 'MALL_COMBO_ORDER_QUERY'],
            type: 'leaf',
            path: '/client/market/mall'
          },
          {
            id: 'CLIENT_MARKET_COUPON',
            message: '卡券管理',
            code: ['MALL_COUPON_QUERY', 'MALL_COUPON_INSTANCE_QUERY', 'MALL_COUPON_INSTANCE_CHECK'],
            type: 'leaf',
            path: '/client/market/coupon'
          },
          { id: 'CLIENT_MARKET_WECHAT', message: '微信图文', code: 'WECHAT_ARTICLE_QUERY', type: 'leaf', path: '/client/market/wechat' }
        ]
      },
      {
        id: 'CLIENT_VISIT',
        message: '回访',
        children: [
          {
            id: 'CLIENT_VISIT_INDEX',
            message: '回访管理',
            code: 'SERVICE_RETURN_VISIT_QUERY',
            type: 'leaf',
            path: '/client/visit'
          },
          {
            id: 'CLIENT_VISIT_HISTORY',
            message: '回访历史',
            code: 'SERVICE_RETURN_VISIT_HISTORY_QUERY',
            type: 'leaf',
            path: '/client/visit/history'
          },
          {
            id: 'CLIENT_VISIT_TAG',
            message: '回访问卷',
            code: 'SERVICE_RETURN_VISIT_TAG_QUERY',
            type: 'leaf',
            path: '/client/visit/tag'
          },
          {
            id: 'CLIENT_VISIT_COMPLAINT',
            message: '投诉处理',
            code: 'SERVICE_COMPLAINT_QUERY',
            type: 'leaf',
            path: '/client/visit/complaint'
          },
          {
            id: 'CLIENT_VISIT_SUMMARY',
            message: '投诉分析',
            code: 'SERVICE_COMPLAINT_SUMMARY_QUERY',
            type: 'leaf',
            path: '/client/visit/summary'
          }
        ]
      },
      {
        id: 'CLIENT_OPPORTUNITY',
        message: '服务',
        children: [
          {
            id: 'CLIENT_OPPORTUNITY_',
            message: '服务管理',
            code: 'BUSINESS_OPPORTUNITY_QUERY',
            type: 'leaf',
            path: '/client/opportunity'
          },
          {
            id: 'CLIENT_OPPORTUNITY_',
            message: '服务历史',
            code: 'BUSINESS_OPPORTUNITY_RECORD_QUERY',
            type: 'leaf',
            path: '/client/opportunity/record'
          },
          {
            id: 'CLIENT_OPPORTUNITY_',
            message: '类型设置',
            code: 'BUSINESS_OPPORTUNITY_CATEGORY',
            type: 'leaf',
            path: '/client/opportunity/category'
          },
          {
            id: 'CLIENT_OPPORTUNITY_',
            message: '评价排名',
            code: 'SERVICE_ESTIMATE_QUERY',
            type: 'leaf',
            path: '/client/opportunity/estimate'
          },
          {
            id: 'CLIENT_OPPORTUNITY_',
            message: '呼叫中心',
            code: 'CALL_RECORD',
            type: 'leaf',
            path: '/client/opportunity/callrecord'
          }
        ]
      }
    ]
  },
  {
    id: 'SETTING',
    message: '设置',
    children: settingMenus.map((item) => {
      return {
        id: item.id,
        message: item.message,
        children: [
          {
            id: item.id + '_SETTING',
            message: item.message + '设置',
            type: 'leaf',
            path: item.children?.[0].path,
            code: item.children?.reduce((codes, child) => {
              return codes.concat(child.code ? (Array.isArray(child.code) ? child.code : [child.code]) : [])
            }, [] as string[])
          }
        ]
      }
    })
  },
  {
    id: 'SPRAY',
    message: '喷涂',
    children: [
      {
        id: 'SPRAY_SERVICE',
        message: '维修',
        children: [
          {
            id: 'SPRAY_SERVICE_WORKORDER',
            message: '喷涂工单',
            code: ['WORK_ORDER_TENANT_QUERY', 'WORK_ORDER_ENOCH_QUERY'],
            type: 'leaf',
            path: '/spray/service/workorder'
          },
          {
            id: 'SPRAY_SERVICE_FORMULA',
            message: '配方查询',
            code: ['FORMULA_QUERY_ENOCH', 'FORMULA_QUERY_TENANT'],
            type: 'leaf',
            path: '/spray/service/formula'
          },
          {
            id: 'SPRAY_SERVICE_FORMULA',
            message: '喷涂看板',
            code: 'WORK_ORDER_TENANT_CREATE_OR_UPDATE',
            type: 'leaf',
            path: '/spray/service/dashboard'
          },
          { id: 'SPRAY_SERVICE_FORMULA', message: '配方验证', code: 'FORMULA_QUERY_ENOCH', type: 'leaf', path: '/spray/service/formula-verify' }
        ]
      },
      {
        id: 'SPRAY_PURCHASE',
        message: '采购',
        children: [
          { id: 'SPRAY_PURCHASE_REPLENISHMENT', message: '补货', code: 'REPLENISHMENT_QUERY', type: 'leaf', path: '/spray/purchase/replenishment' },
          { id: 'SPRAY_PURCHASE_FORMULA', message: '巴斯夫订单', code: 'REPLENISHMENT_QUERY', type: 'leaf', path: '/spray/purchase/dashboard' },
          { id: 'SPRAY_PURCHASE_FORMULA', message: '采购入库', code: 'REPLENISHMENT_QUERY', type: 'leaf', path: '/spray/purchase/formula-verify' }
        ]
      },
      {
        id: 'SPRAY_INVENTROY',
        message: '库存',
        children: [
          {
            id: 'SPRAY_INVENTROY_WORKORDER',
            message: '喷涂领料单',
            code: ['MATERIAL_REQUISITION_TENANT', 'MATERIAL_REQUISITION_ENOCH'],
            type: 'leaf',
            path: '/spray/inventory/requestion'
          },
          { id: 'SPRAY_INVENTROY_FORMULA', message: '喷涂盘点单', code: 'MATERIAL_REQUISITION_TENANT', type: 'leaf', path: '/spray/inventory/stock' },
          {
            id: 'SPRAY_INVENTROY_FORMULA',
            message: '喷涂盘点结算单',
            code: 'MATERIAL_REQUISITION_TENANT',
            type: 'leaf',
            path: '/spray/inventory/stock-settlement'
          },
          {
            id: 'SPRAY_INVENTROY_FORMULA',
            message: '配件生产',
            code: 'MANUFACTURING_PRODUCTION_QUERY',
            type: 'leaf',
            path: '/spray/inventory/manufacturing'
          }
        ]
      },
      {
        id: 'SPRAY_SETTLEMENT',
        message: '财务',
        children: [
          {
            id: 'SPRAY_SETTLEMENT_WORKORDER',
            message: '喷涂对账',
            code: ['PAINT_STATEMENT_TENANT', 'PAINT_STATEMENT_ENOCH'],
            type: 'leaf',
            path: '/spray/settlement/statement/paint'
          },
          {
            id: 'SPRAY_SETTLEMENT_FORMULA',
            message: '配件对账',
            code: ['SALE_STATEMENT_TENANT', 'SALE_STATEMENT_ENOCH'],
            type: 'leaf',
            path: '/spray/settlement/sale'
          }
        ]
      },
      {
        id: 'SPRAY_REPORT',
        message: '报表',
        children: [
          {
            id: 'SPRAY_REPORT_WORKORDER',
            message: '喷涂业务统计',
            code: ['BUSINESS_SUMMARY_QUERY', 'BUSINESS_SUMMARY_QUERY_CURRENT'],
            type: 'leaf',
            path: '/spray/report/business'
          },
          {
            id: 'SPRAY_REPORT_FORMULA',
            message: '喷涂经营报告',
            code: ['BUSINESS_EVALUATION_ENOCH_QUERY', 'BUSINESS_EVALUATION_TENANT_QUERY'],
            type: 'leaf',
            path: '/spray/report/evaluation'
          },
          {
            id: 'SPRAY_REPORT_FORMULA',
            message: '其他统计',
            code: ['BUSINESS_SUMMARY_QUERY', 'BUSINESS_SUMMARY_QUERY_CURRENT'],
            type: 'leaf',
            path: '/spray/service/dashboard'
          }
        ]
      }
    ]
  }
]

const ROOT = Symbol('root')
const PARENT = Symbol('parent')
const IS_REACTIVE = Symbol('is_reactive')

export type ReactiveMenu = Omit<MenuConfig, 'children'> & {
  [IS_REACTIVE]: boolean
  [ROOT]: MenuConfig
  [PARENT]: MenuConfig
  children?: ReactiveMenu[]
  visible: boolean
}

const proxyMap = new WeakMap()
const cookies = new Cookies()

const createMenuProxy = (target: MenuConfig, root?: ReactiveMenu, parent?: ReactiveMenu): ReactiveMenu => {
  if ((target as ReactiveMenu)[IS_REACTIVE]) {
    return target as ReactiveMenu
  }

  const existing = proxyMap.get(target)
  if (existing) return existing

  const handler: ProxyHandler<ReactiveMenu> = {
    get(target, key, receiver) {
      const res = Reflect.get(target, key, receiver)

      switch (key) {
        case IS_REACTIVE:
          return true
        case ROOT:
          return root
        case PARENT:
          return parent
        case 'children':
          return (res as MenuConfig[])?.map((menu) => createMenuProxy(menu, root, proxy))
        default:
          return res
      }
    },
    set(target, key, value, receiver) {
      const res = Reflect.set(target, key, value, receiver)

      switch (key) {
        case 'visible':
          if (target.id === 'SETTING_ENOCH' && cookies.get('ENOCH_TENANT', { path: '/' }) !== 'ENOCH') target.visible = false
          if (parent) parent.visible = Boolean(parent.children?.find((item) => item.visible))
          return res
      }
      return res
    }
  }

  const proxy = new Proxy(target, handler) as ReactiveMenu

  if (!root) root = proxy

  proxyMap.set(target, proxy)

  return proxy
}

export const reactiveMenus = menus.map((menu) => createMenuProxy(menu))
export const reactiveSettingMenus = settingMenus.map((menu) => createMenuProxy(menu))

const _normalizedMenus = <T extends { children?: T[] }>(menus: T[], handler?: (menu: T) => T): T[] => {
  return menus.map((item) => {
    if (item.children) item.children = _normalizedMenus(item.children, handler)
    return handler?.(item) ?? item
  }) as T[]
}

const _filterMenus = <T extends { children?: T[] }>(data: T[], predicat: (menu: T) => boolean) => {
  return data.filter((item) => {
    const valid = predicat(item)
    if (valid && item.children) item.children = _filterMenus(item.children, predicat)
    return valid
  })
}

const computedMenuRef = (rm: ReactiveMenu[], accessRights: string[]) => {
  return _filterMenus(
    _normalizedMenus(rm, (menu) => {
      if (menu.type === 'leaf' && menu.code) {
        const codes = typeof menu.code == 'string' ? [menu.code] : menu.code
        menu.visible = new Set([...accessRights, ...codes]).size !== accessRights.length + codes.length
      }
      return menu
    }),
    (menu) => menu.visible
  )
}

export const useMenus = () => {
  const store = useStore()

  const data = computed(() => computedMenuRef(reactiveMenus, (store.user.accessRights as string[]) ?? []))

  const settingMenus = computed(() => computedMenuRef(reactiveSettingMenus, (store.user.accessRights as string[]) ?? []))

  return { data, settingMenus }
}
