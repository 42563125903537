import { onMounted, ref, unref, watchEffect } from 'vue'
import { ajax } from '@enocloud/utils'

import type { Ref } from 'vue'
import type { AjaxActionsMap } from '@enocloud/utils'

type MaintainType = 'select' | 'tabs' | 'checkbox' | 'cascader'

type MaybeRef<T> = T | Ref<T>

export type MaintainAjaxConfig = {
  [K in keyof AjaxActionsMap]: {
    action: K
    params?: (payload: { payload: AjaxActionsMap[K]['server']; paths: any[] }, ...args: any[]) => void
    convert?: (data: AjaxActionsMap[K]['client'][]) => any
  }
}[keyof AjaxActionsMap]

interface MaintainOptions<T> {
  ajax?: MaintainAjaxConfig
  data?: MaybeRef<any[] | undefined>
  lazy?: boolean
}

interface MaintainReturn<T> {
  data: Ref<any[]>
  loading: Ref<boolean>
  run: ((...args: any) => Promise<any>) | null
}

export const useMaintain = <T extends MaintainType>(type: T, options: MaintainOptions<T>): MaintainReturn<T> => {
  const { ajax: _ajax, data: defaultData, lazy } = options
  const data = ref<any[]>([])
  const loading = ref(false)

  let run: (() => Promise<any>) | null = null

  if (_ajax) {
    const { action, params, convert } = _ajax
    run = async (...args: any[]) => {
      const ajaxParams: any = { paths: [], payload: {} }
      params?.(ajaxParams, ...args)
      loading.value = true
      try {
        const res: any = await ajax(action, ajaxParams)
        data.value = convert ? convert(res.data) : res.data
        return Promise.resolve(res)
      } catch (err) {
        return Promise.reject(err)
      } finally {
        loading.value = false
      }
    }

    if (!lazy) onMounted(() => run?.())
  } else {
    watchEffect(() => {
      data.value = unref(options.data) ?? []
    })
  }

  return {
    data,
    loading,
    run
  }
}
